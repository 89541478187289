import { hideVisually } from 'polished'
import styled from 'styled-components'
import { type PageTitleData } from '@/features/cms/components/CMS/types'
import { Display } from '@shipt/design-system-typography'
import { CMSContainer } from '@/features/cms/components/CMS/CMSContainer'

/**
 * This component corresponds to the `page_title` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/page_title
 * - **Production** - N/A
 */
export function PageTitle({ content_type_id, data }: Readonly<PageTitleData>) {
  const { alignment, hidden, title } = data
  return (
    <CMSContainer contentTypeId={content_type_id} id={content_type_id}>
      <Title $hidden={hidden} as="h1" size="xl" align={alignment}>
        {title}
      </Title>
    </CMSContainer>
  )
}

const Title = styled(Display)<{ $hidden: boolean }>`
  ${({ $hidden }) => $hidden && hideVisually}
`
