import { CMSSection } from '@/features/cms/components/CMS/CMSSection'
import { coreLayoutCmsComponents } from '@/features/cms/components/CMS/CoreLayoutCMSSection'
import {
  type CmsMappedComponents,
  type Content,
} from '@/features/cms/components/CMS/types'
import dynamic from 'next/dynamic'
import { type ComponentProps } from 'react'

/* PLOP_INJECT_DYNAMIC_IMPORT */

const DynamicGiftCardPurchaseForm = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/GiftCardPurchaseForm'
      )
    ).GiftCardPurchaseForm
)
const DynamicLayoutGroup = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/LayoutGroup'))
      .LayoutGroup
)
const DynamicHeader = dynamic(
  async () =>
    (await import('@/features/shared/components/Headers/CMSHeader')).CMSHeader
)
const DynamicSpecializedHeader = dynamic(
  async () =>
    (
      await import(
        '@/features/shared/components/Headers/CMSHeader/SpecializedHeader'
      )
    ).SpecializedHeader
)
const DynamicSplitHeroV2 = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/SplitHeroV2'))
      .SplitHeroV2
)
const DynamicHeadlineContainer = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/HeadlineContainer'))
      .HeadlineContainer
)
const DynamicFooter = dynamic(
  async () =>
    (await import('@/features/shared/components/Footers/CMSFooter')).CMSFooter
)
const DynamicAnnouncementBannerV2 = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/AnnouncementBannerV2'
      )
    ).AnnouncementBannerContainer
)
const DynamicRetailerHero = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/RetailerHero'))
      .RetailerHero
)
const DynamicFullWidthHero = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/FullWidthHero'))
      .FullWidthHero
)
const DynamicFullWidthSplitSection = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/FullWidthSplitSection'
      )
    ).FullWidthSplitSection
)
const DynamicSplitInfoAccordion = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/SplitInfoAccordion'
      )
    ).SplitInfoAccordion
)
const DynamicDemoSlider = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/DemoSlider'))
      .DemoSlider
)
const DynamicSecondaryStorytelling = dynamic(
  async () =>
    (
      await import(
        '@/features/cms/components/CMS/components/SecondaryStorytelling'
      )
    ).SecondaryStorytelling
)
const DynamicCTABanner = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/CTABanner'))
      .CTABanner
)
const DynamicAccordion = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/Accordion'))
      .AccordionLayout
)
const DynamicMultiStory = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/MultiStory'))
      .MultiStory
)
const DynamicPlaceholder = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/Placeholder'))
      .Placeholder
)
const DynamicRichTextRenderer = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/RichTextRenderer'))
      .RichTextRenderer
)
const DynamicMembershipPricing = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/MembershipPricing'))
      .MembershipPricing
)
const DynamicTestimonialSection = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/Testimonials'))
      .TestimonialSection
)
const DynamicCarousel = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/Carousel')).Carousel
)
const DynamicRetailerLogos = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/RetailerLogos'))
      .RetailerLogos
)
const DynamicOverlapHero = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/OverlappingHero'))
      .OverlappingHero
)
const DynamicPartnershipBanner = dynamic(
  async () =>
    (await import('@/features/cms/components/CMS/components/PartnershipBanner'))
      .PartnershipBanner
)
const DynamicZipCheckForm = dynamic(
  async () =>
    (await import('@/features/entrypoint/components/Marketing/ZipCheckForm'))
      .ZipCheckForm
)

export const pageLayoutCmsComponents = {
  /* PLOP_INJECT_CMS_SECTION_COMPONENT */
  accordion: DynamicAccordion,
  announcement_banner_container: DynamicAnnouncementBannerV2,
  carousel: DynamicCarousel,
  cta_banner: DynamicCTABanner,
  demo_slider_container: DynamicDemoSlider,
  footer: DynamicFooter,
  full_width_hero: DynamicFullWidthHero,
  full_width_split_section: DynamicFullWidthSplitSection,
  gift_card_form: DynamicGiftCardPurchaseForm,
  header: DynamicHeader,
  headline_container: DynamicHeadlineContainer,
  layout_group: DynamicLayoutGroup,
  legal: DynamicRichTextRenderer,
  membership_pricing: DynamicMembershipPricing,
  multistory: DynamicMultiStory,
  overlap_hero: DynamicOverlapHero,
  partnership_banner: DynamicPartnershipBanner,
  retailer_hero: DynamicRetailerHero,
  retailer_logos: DynamicRetailerLogos,
  secondary_storytelling: DynamicSecondaryStorytelling,
  segway_placeholder: DynamicPlaceholder,
  fe_placeholder: DynamicPlaceholder,
  specialized_header: DynamicSpecializedHeader,
  split_hero_v2: DynamicSplitHeroV2,
  split_info_accordion_container: DynamicSplitInfoAccordion,
  testimonial: DynamicTestimonialSection,
  zip_check: DynamicZipCheckForm,
  ...coreLayoutCmsComponents,
} satisfies CmsMappedComponents<Content['content_type_id']>

export const PageLayoutCMSSection = (
  props: Omit<ComponentProps<typeof CMSSection>, 'components'>
) => <CMSSection {...props} components={pageLayoutCmsComponents} />
