import { type ComponentType } from 'react'
import { type CMSPageData } from '@/features/cms/components/CMS/types'
import { GlobalHomePageLayout } from '@/features/cms/components/CMS/components/Layouts/GlobalHomePageLayout'
import { RetailerHomePageLayout } from '@/features/cms/components/CMS/components/Layouts/RetailerHomePageLayout'
import { PageLayout } from '@/features/cms/components/CMS/components/Layouts/PageLayout'
import { MarketplacePageLayout } from '@/features/cms/components/CMS/components/Layouts/MarketplacePageLayout'
import { isValidLayoutContentTypeID } from '@/features/cms/components/CMS/utils'
import { AliasLayout } from '@/features/cms/components/CMS/components/Layouts/AliasLayout'
import { CMSLayoutContextProvider } from '@/features/cms/components/CMS/CMSLayoutContext'

export const layoutComponents: Record<
  CMSPageData['content']['content_type_id'],
  // Using "any" type to allow dynamic component rendering with various prop/CMS field types
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ComponentType<any>
> = {
  page_layout: PageLayout,
  global_home_page_layout: GlobalHomePageLayout,
  retailer_home_page_layout: RetailerHomePageLayout,
  marketplace_page_layout: MarketplacePageLayout,
  alias_layout: AliasLayout,
}

export const LayoutRenderer = ({
  layoutData,
}: {
  layoutData: CMSPageData['content']
}) => {
  const contentTypeId = layoutData.content_type_id

  if (!isValidLayoutContentTypeID(contentTypeId)) return null

  const Component = layoutComponents[contentTypeId]
  return (
    <CMSLayoutContextProvider data={layoutData.content_type_id}>
      <Component layoutData={layoutData} />
    </CMSLayoutContextProvider>
  )
}
