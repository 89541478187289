import { PageLayoutCMSSection } from '@/features/cms/components/CMS/PageLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

export const AliasLayout = ({
  data: { data, content_type_id, id },
}: {
  data: CMSPageDataWithSpecificLayout<'alias_layout'>['content']
}) => {
  return (
    <div {...getCMSWrapperProps({ content_type_id, id })}>
      {data && <PageLayoutCMSSection section={data.body} />}
    </div>
  )
}
