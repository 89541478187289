import { CoreLayoutCMSSection } from '@/features/cms/components/CMS/CoreLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import { screenSizes } from '@shipt/design-system-themes'
import styled from 'styled-components'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { PageLayoutCMSSection } from '@/features/cms/components/CMS/PageLayoutCMSSection'

/**
 * This component corresponds to the `retailer_home_page_layout` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/retailer_home_page_layout
 * - **Production** - https://cms.shipt.com/content-type/pool/retailer_home_page_layout
 */
export const RetailerHomePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'retailer_home_page_layout'>['content']
}) => {
  const sectionAandB = (data?.body_section_a ?? []).concat(
    data?.body_section_b ?? []
  )
  return (
    <>
      {data?.announcement && (
        <PageLayoutCMSSection section={data.announcement} />
      )}
      <Wrapper {...getCMSWrapperProps({ content_type_id, id })}>
        {sectionAandB && <CoreLayoutCMSSection section={sectionAandB} />}
      </Wrapper>
    </>
  )
}
const Wrapper = styled.div`
  @media ${screenSizes.tablet} {
    margin-top: 1rem;
  }
`
