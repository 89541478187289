import { CoreLayoutCMSSection } from '@/features/cms/components/CMS/CoreLayoutCMSSection'
import { type CMSPageDataWithSpecificLayout } from '@/features/cms/components/CMS/types'
import styled from 'styled-components'
import { screenSizes, spacingRem } from '@shipt/design-system-themes'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'

/**
 * This component corresponds to the `marketplace_page_layout` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/marketplace_page_layout
 * - **Production** - https://cms.shipt.com/content-type/pool/marketplace_page_layout
 */
export const MarketplacePageLayout = ({
  layoutData: { id, content_type_id, data },
}: {
  layoutData: CMSPageDataWithSpecificLayout<'marketplace_page_layout'>['content']
}) => {
  const isHeaderBanner =
    data?.body?.some(
      (item, i) => item.content_type_id === 'header_banner' && i === 0
    ) || false
  return (
    <MarketplacePageLayoutContainer
      shouldNotAddMargin={isHeaderBanner}
      {...getCMSWrapperProps({ content_type_id, id })}
    >
      {data?.body && <CoreLayoutCMSSection section={data.body} />}
    </MarketplacePageLayoutContainer>
  )
}

const MarketplacePageLayoutContainer = styled.div<{
  shouldNotAddMargin: boolean
}>`
  margin-top: ${({ shouldNotAddMargin }) =>
    shouldNotAddMargin ? 0 : spacingRem('lg')};

  @media ${screenSizes.tablet} {
    margin-top: ${({ shouldNotAddMargin }) =>
      shouldNotAddMargin ? 0 : spacingRem('xl')};
  }

  @media ${screenSizes.smDesktop} {
    margin-top: ${spacingRem('xl')};
  }
`
